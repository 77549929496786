import * as React from "react";

const Component = ({ location }) => {
  const pathArr = location.pathname?.split('/');
  pathArr.shift();
  pathArr.shift();
  let path = pathArr.join('/');
  React.useEffect(() => {

window.location.href  = `/${path}`;

}, [path])

return null;
}

export default Component
